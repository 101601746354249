import { augmentedOccasionsApi } from 'api'
import { InspirationDocumentDto } from 'types/dto/augmentedOccasionsDto'

export interface InspirationsParams {
  query: string
  documentsLimit: number
}

export const fetchInspirations = ({ query, documentsLimit }: InspirationsParams) =>
  augmentedOccasionsApi.get<InspirationDocumentDto[]>('/documents', {
    params: { query, documentsLimit },
  })
