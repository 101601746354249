import { WppTypography, WppSkeleton, WppExpandableCard } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useFetchCreatives } from 'api/augmentedOccasions/queries/useFetchCreatives'
import { CreativeCard } from 'components/augmentedOccasionsResult/creatives/creativeCard/CreativeCard'
import styles from 'components/augmentedOccasionsResult/creatives/Creatives.module.scss'
import { OccasionItemDto } from 'types/dto/augmentedOccasionsDto'

interface Props {
  occasion: OccasionItemDto
}
export const Creatives = ({ occasion }: Props) => {
  const { t } = useTranslation()

  const { data: creatives, isLoading: isLoadingCreatives } = useFetchCreatives({
    params: {
      channelId: occasion.augmentedOccasionChannel.id,
      zoneId: occasion.augmentedOccasionZone.id,
    },
  })

  return (
    <WppExpandableCard expanded className={styles.card}>
      <WppTypography type="m-strong" slot="header">
        {t('common.optimal')}
      </WppTypography>
      <div className={styles.creativesListContainer}>
        {isLoadingCreatives ? (
          <>
            {[...Array(12)].map((e, i) => (
              <WppSkeleton key={i} variant="rectangle" width="100%" height="255" />
            ))}
          </>
        ) : (
          <>{creatives && creatives.map(creative => <CreativeCard key={creative.id} creative={creative} />)}</>
        )}
      </div>
    </WppExpandableCard>
  )
}
