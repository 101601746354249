import { augmentedOccasionsApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { CreateScoreImpactReportBody, ScoreImpactReportResponseDto } from 'types/dto/chartsReportDto'

export interface Props {
  body: CreateScoreImpactReportBody
}

export const createScoreImpactReport = ({ body }: Props): CancelableRequestProducer<ScoreImpactReportResponseDto> =>
  augmentedOccasionsApi.post('/report/score-impact', body)
